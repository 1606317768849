import MainService from './MainService'
class PropertiesService extends MainService {

    constructor() {
        super()
        this.api_name = "ApolarNew"
        this.api_path = "properties"
    }


    getByReference = async(reference, index_name=null, query_parms='') => {
        return this.doRequest("get",`reference/${reference}${index_name ? '/' + index_name : ''}${query_parms}`)
    }

    getFilters = async(index_name=null) => {
        return this.doRequest("get", `filters${index_name ? '/' + index_name : ''}`)
    }

    search = async(search_data, index_name=null) => {
        return this.doRequest("post",`search${index_name ? '/' + index_name : ''}`, search_data)
    }

    similarSearch = async(search_data, index_name=null) => {
        return this.doRequest("post",`similar-search${index_name ? '/' + index_name : ''}`, search_data)
    }

    customSearch = async(search_data, index_name=null) => {
        return this.doRequest("post",`custom-search${index_name ? '/' + index_name : ''}`, search_data)
    }

    similar = async(property, index_name=null) => {
        return this.doRequest("post",`similar${index_name ? '/' + index_name : ''}`, {"property":property})
    }

    getCondominiumBySlug = async(slug, query_parms='') => {
        return this.doRequest("get",`condominium/${slug}${query_parms}`)
    }

    getCondominiumsArround = async(search_data, index_name=null) => {
        return this.doRequest("post",`condominium/arround`, search_data)
    }

    getStaticMapImage = async(reference, index_name=null) => {
        return this.doRequest("get",`static-map/${reference}${index_name ? '/' + index_name : ''}`)
    }


}

export default new PropertiesService();