<template>
  <div class="v-select-multile-component" @mouseleave="select_opened = false">
  <!-- <div class="v-select-multile-component"> -->
    <div @click="openSelect" class="v-select-multile-iterable" :class="iterableAreaClass">
      <input v-show="toggleInput" readonly="true" type="text" :placeholder="placeholder">
      <div v-show="!toggleInput" class="v-select-multile-selected">
        {{values[0]}} 
        <span v-if="hasMoreSelectedValue" class="v-select-multile-selected-more">
          + {{hasMoreSelectedValue}} <span v-if="hasMoreSelectedValue > 1">opções</span> <span v-else>opção</span>
        </span>
      </div>
    </div>
    
    <div v-show="select_opened" class="v-select-multile-options-wrapper">
      <div class="v-select-multile-options-search">
        <input v-model="search" @input="paged_options = []; current_page=1;" type="text" :placeholder="`Buscar...`">
      </div>
      <ul  class="v-select-multile-options">
        <template v-for="opt, key in use_options">
          <li 
            v-if="typeof opt != 'undefined'"
            v-bind:key="`option_${key}`"
            @click="handleChange(typeof opt['id'] == 'undefined' ? opt : opt['id'])" 
            :class="handleOptionClass(typeof opt['id'] == 'undefined' ? opt : opt['id'])"
          >
            <div class="input-checkbox"></div>
            <label for="">{{typeof opt['label'] == 'undefined' ? __(opt) : __(opt['label'])}}</label>
          </li>
        </template>

        <li 
          v-if="pages > 1 && filtered_options.length > paged_options.length" 
          @click="current_page +=1"
          class="v-select-multile-options-load">...</li>

        <li v-if="filtered_options.length == 0" class="no-options">{{__(empty_label)}}</li>
      </ul>
    </div>

  </div>
</template>

<script>

export default {
  name: 'VSelectMultiple',
  components: {
  },
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    modelValue: String,
    placeholder: {
        type: String,
        default() {
            return "Buscar"
        }
    },
    empty_label: {
        type: String,
        default() {
            return "Nenhuma opção encontrada."
        }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    min_search: {
      type: Boolean,
      default() {
        return false
      }
    },
    limit_show: {
      type: [String, Number],
      default() {
        return 10
      }
    },
    value: Array
  },
  data: () => ({
    observer: null,
    limit: 10,
    search: '',
    select_opened: false,
    values: [],
    pages: 1,
    current_page: 1,
    paged_options: [],
    filtered_options: []
  }),
  computed: {
   iterableAreaClass() {
      let _class = "";
      _class += this.select_opened ? 'opened' : 'closed'
      return _class
   },
   toggleInput() {
    return this.values.length == 0 || this.select_opened == true ? true : false
   },
   hasMoreSelectedValue() {
    return this.values.length > 1 ? this.values.length - 1 : 0
   },
   use_options() {
      this.filtered_options = this.search.length ? this.options.filter((opt) => {
            try {opt = opt.toString()} catch (error) {}
            return opt.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').toLowerCase().indexOf(
                this.search.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').toLowerCase()
            ) != -1
        } ) : this.options

      if (this.filtered_options.length > this.limit_show ) {
        this.pages = this.filtered_options.length / this.limit_show
        this.paged_options = this.paged_options.concat(this.filtered_options.slice((this.current_page -1) * this.limit_show, this.current_page * this.limit_show))
        return this.paged_options
      } else {
        this.pages =  1
        return this.filtered_options
      }
      
      
   }
  },
  mounted() {

    console.log(this.$el.querySelector(".v-select-multile-options"))
    this.$el.querySelector(".v-select-multile-options").addEventListener('scroll',()=>{
      let options_el = this.$el.querySelector(".v-select-multile-options")
      if ( (options_el.offsetHeight + options_el.scrollTop) > options_el.scrollHeight && this.pages > 1 && this.filtered_options.length > this.paged_options.length ) {
        this.current_page +=1
      }
    })
    
  },
  methods: {
    openSelect() {
      this.select_opened = !this.select_opened
      this.current_page =  1
      this.paged_options = []

      if (this.select_opened) {
       
       setTimeout(() => {
          this.$el.querySelector('.v-select-multile-options-search input').focus()
       }, 100);
        
      }
    },

    handleOptionClass(value) {
      let _class = "v-select-multile-option "
      _class += this.values.indexOf(value) != -1 ? 'selected' : ''
      return _class
    },

    handleChange(value) {
      let value_index = this.values.indexOf(value)
      if (value_index != -1) {
        this.values.splice(value_index, 1)
      } else {
        this.values.push(value)
      }

      this.$emit('input', this.values)

    }
  },
  watch: {
    value: function(value) {
      this.values = value.map((v) => {
        return typeof v == "object" ? v.id : v
      })
    },
    options: function(options) {
      this.pages = options.length / this.limit_show
    }
  }
}
</script>