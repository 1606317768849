export default {
    "Apolar Imóveis": "Apolar inmuebles",
    "Imóvel": "Inmoble",
    "Comprar": "Compra",
    "Lançamentos": "Lanzamientos",
    "Residencial": "Residencial",
    "Feirão Apolar": "Ofertas Apolar",
    "Alugar": "Alquilar",
    "Produtos Apolar": "Productos Apolar",
    "Apolar Procura": "Apolar Busca",
    "Consórcio": "Consorcio",
    "Para Investidores": "Para inversores",
    "Built for Business": "Construido para los negocios",
    "Nossas Lojas": "Nuestras Tiendas",
    "Indique": "Indicar",
    "Ops! Aluguei": "Alquiler Digital",
    "Anunciar Imóveis": "Anunciar propiedades",
    "Seja um Franqueado":"Sea un Franquiciado",
    "Área Cliente": "Área de cliente",
    "A mais completa imobiliária de Curitiba no Paraná e Santa Catarina":"La Agencia Inmobiliaria más completa de Paraguay.",
    "Encontre os melhores imóveis para comprar ou alugar": "Encuentre las mejores propiedades para comprar o alquilar",
    "COMPRAR": "COMPRAR",
    "ALUGAR": "ALQUILAR",
    "Residencial": "Residencial",
    "Mensal": "Mensual",
    "Temporário": "Temporal",
    "Bairro, cidade, endereço ou referência": "Ciudad, dirección o referencia",
    "Cidade": "Ciudad",
    "Procurar por cidade": "Buscar por ciudad",
    "Bairro": "Barrio",
    "Procurar por bairro": "Buscar por barrio",
    "Apartamento":"Departamento",
    "Casa e sobrado":"Casa",
    "Terreno":"Terreno",
    "Studio e kitnet":"Monoambiente",
    "Outros imóveis":"Otras propiedades",
    "Outros tipos de imóveis":"Otros tipos de inmuebles",
    "Tipo de imóveis:":"Tipo de inmuebles:",
    "Selecione o tipo de imóvel":"Seleccione el tipo de inmueble",
    "Quartos": "Cuartos",
    "Nº de quartos": "Nº de cuartos",
    "quartos": 'cuartos',
    "Vagas": "Estacionamiento",
    "Nº de vagas": "Nº de estacionamiento",
    "Fechar filtros avançados":"Cerrar búsqueda avanzada",
    "Filtros Avançados":"Búsqueda avanzada",
    "Limpar filtros":"Limpiar búsqueda",
    "Buscar por:": "Búsqueda por:",
    "ENCONTRAR IMÓVEIS": "BUSCAR INMUEBLES",
    "Sala": "Oficina",
    "Loja": "Local",
    "Barracão": "Depósito",
    "Imóveis Anúnciados": "Propiedades Anunciadas",
    "Outras opções de imóveis": "Otras opciones de propiedad",


    "Pensou em Empreender?\
    <br/>A Apolar Imóveis é uma grande oportunidade para você fazer do seu investimento uma marca de sucesso!\
    Invista em franquias imobiliárias, um modelo de negócio consolidado com uma das líderes do setor imobiliário do sul do país.\
    <br/><br/>Conheça nossos Benefícios!":"Has pensado en emprender?<br/>\
    Apolar Imóveis es una gran oportunidad para que hagas de tu inversión una marca exitosa!\
    Invierta en franquicias inmobiliarias, un modelo de negocio consolidado con uno de los líderes del sector inmobiliario de America Latina.\
    <br/><br/>Conoce nuestros Beneficios",
    "Seja um franqueado":"Sea un franquiciado",

    "Lançamentos Apolar":"Lanzamientos",
    "Seu novo lar pode estar aqui!\
    <br/><br/>Empreendimentos imobiliários novos que podem ser comprados na planta ou já prontos para morar.\
    <br/><br/>Apolar Lançamentos, projetando o futuro com você!":"Tu nuevo hogar puede estar aquí!\
    <br/><br/>Nuevos desarrollos inmobiliarios que se pueden comprar en la planta o listos para mudarse.\
    <br/><br/>¡Apolar Lanzamientos, diseñando el futuro contigo!",
    "Veja os lançamentos apolar":"Vea los lanzamientos de Apolar",

    "Apolar Short Stay":"Corta Estadía",
    "Com a Short Stay você encontra imóveis com vantagens e flexibilidade na moradia de curta estadia para aluguel temporário.<br/><br/>\
    Nosso processo de locação é ágil e rápido, com contratos flexíveis e imóveis 100% equipados.<br/><br/>\
    Aqui na Short Stay temos o imóvel pronto para você morar!":"Con Corta Estadía encontrar propiedades con beneficios y flexibilidad en viviendas de corta estadía para alquiler temporal.<br/><br/>\
    Nuestro proceso de arrendamiento es ágil y rápido, con contratos flexibles y propiedades 100% equipadas.<br/><br/>\
    ¡Aquí en Short Stay tenemos la propiedad lista para que vivas en ella!",
    "Buscar imóveis short stay":"Buscar propiedades para estadías cortas",

    "Invista":"Invertir",
    "Invista em imóveis e veja seus rendimentos aumentarem.<br/><br/>\
    Não fique com dinheiro parado no banco ou em investimentos que não geram retorno.<br/><br/>\
    Invista  Agora! É totalmente seguro, o imóvel é valorizado gerando lucro para você  investidor.":"Invierte en inmuebles y verás aumentar tu capital. No guardes dinero en el banco ni en inversiones que no te generen retorno.<br/><br/>\
    ¡Invierta ahora! Es totalmente seguro, la propiedad se valoriza generando rentabilidad para ti inversionista.",
    "Ver todos os imóveis":"Ver todas las propiedades",

    "Built for business":"B4B",
    "Construído para servir!<br/><br/>\
    É um modelo focado no ramo comercial, onde o imóvel é construído ou reformado especificamente para as necessidades da sua empresa.<br/><br/>\
    Pensado para você, personalizado para a sua empresa.":"Construido para servir! Es un modelo enfocado al sector comercial, donde el inmueble se construye o reforma específicamente para las necesidades de tu empresa.<br/><br/>\
    Hecho para usted, personalizado para tu empresa",

    "DESCONTOS para o seu lar!<br/><br/>\
    No feirão Apolar você encontra imóveis com valores imperdíveis.<br/><br/>\
    Não espere mais! compre agora o seu imóvel dos sonhos com descontos Reais!":"¡DESCUENTOS para tu casa! En la feria Apolar encontrarás propiedades con valores imperdibles.<br/><br/>\
    ¡No esperes más! ¡Compre la propiedad de sus sueños ahora con descuentos reales!",
    "Ver imóveis em oferta":"Ver propiedades en oferta!",

    "Real estate - Triple A":"TRIPLE A",
    "A Apolar Triplo A é referência em imóveis de alto padrão, oferecendo os melhores negócios com uma assessoria personalizada\
    pelos nossos profissionais na área. Com a Real Estate os seus investimentos aumentam com segurança.":"Apolar Triple A es un referente en inmobiliaria de alto nivel, ofreciendo las mejores ofertas con el asesoramiento\
    personalizado de nuestros profesionales en la zona. Con Real Estate tus inversiones aumentan de forma segura.",
    "Buscar por modalidade":"Buscar por modalidad",

    "Com foco em imóveis no litoral, esse tipo de estadia foge da hospedagem convencional, oferecendo diversas vantagens.<br/><br/>\
    Aumente sua sensação de liberdade e conforto, alugue agora um imóvel e desfrute!":"Con un enfoque en propiedades en la costa, este tipo de estancia es más que un alojamiento convencional, ofreciendo muchas más ventajas.<br/><br/>\
    ¡Aumente su sensación de libertad y comodidad, alquile una propiedad ahora y disfrute!",

    "Invista, realize e conquiste!<br/><br/>\
    Com o consórcio Apolar, administrado pela Randon Consórcios, você escolhe uma parcela que cabe no seu bolso.<br/><br/>\
    É super fácil, escolha o valor do crédito e defina o tipo de carta de seu interesse.":"¡Invierte, realiza y conquista!<br/><br/>\
    Con el consorcio Apolar, administrado por Randon Consórcios, eliges una porción dentro de tu plan financiero.<br/><br/>\
    Es súper fácil, elige el valor del crédito y define el tipo de carta que te interesa.",
    "Faça uma simulação":"Hacer una simulación",

    "Apolar procura":"Búsqueda Apolar",
    "Ainda não encontrou o imóvel que deseja?<br/><br/>\
    Você pode fazer um anúncio e divulgar exatamente as especificações do imóvel que está procurando.<br/><br/>\
    E conectamos você, proprietário, com os interessados que buscam imóveis como o seu. Um match perfeito!":"¿Aún no ha encontrado la propiedad que desea?<br/><br/>\
    Puede colocar un anuncio y revelar exactamente las especificaciones de la propiedad que está buscando.<br/><br/>\
    Y te conectamos a ti, el propietario, con interesados ​​que buscan propiedades como la tuya. ¡Una combinación perfecta!",
    "Descubra o imóvel perfeito":"Descubre la propiedad perfecta",

    "Quer ganhar um dinheiro extra e não sabe como ?<br/>\
    Indique agora  imóveis na Ops  Ganhei!<br/><br/>\
    Você indica um imóvel na OPS! Ganhei e ganha R$150,00 por imóvel que for publicado. Simples assim!":"¿Quieres ganar un dinero extra y no sabes cómo?<br/><br/>\
    Indique ahora las propiedades en OPS! Ganhei.<br/><br/>\
    Indicas una propiedad en OPS! Ganhei y gana R$ 150,00 por propiedad que se publica. ¡Simples así!",
    "Indique agora":"Indicar ahora",

    "Anuncie seu imóvel sem complicações!<br/><br/>\
    Com a Apolar, anunciar pode ser rápido e fácil. Conheça agora todas as vantagens.":"¡Anuncie su propiedad sin complicaciones!<br/><br/>\
    Con Apolar, la publicidad puede ser rápida y fácil. Descubra ahora todas las ventajas.",
    "Anuncie agora":"Anuncia ahora",

    "Imóveis com valores inacreditáveis!":"¡Propiedades con valores increíbles!",
    "Ver todas as ofertas":"Ver todas las ofertas",
    "Visitar a página do Feirão Apolar":"Visite la página de ofertas Apolar",

    "Visitar a página de Lançamentos Apolar":"Visite la página de lanzamientos de Apolar",
    "Confira os novos empreendimentos Apolar":"Conoce los nuevos emprendimientos de Apolar",
    "Ver lançamentos":"Ver lanzamientos",



    "Quer anunciar seu imóvel?":"¿Quiere anunciar su propiedad?",
    "Anuncie seu imóvel no maior portal imobilário do Brasil":"Anuncie su propiedad en el portal inmobiliario más grande de Brasil",
    "Muito mais que uma plataforma, nosso atendimento e assessoria são humanizados e com profissionais que atuam no mercado há mais de 50 anos.":"Mucho más que una plataforma, nuestro servicio y asesoría son humanizados y con profesionales que llevan más de 50 años trabajando en el mercado.",
    "Saiba mais sobre a Apolar":"Más información sobre Apolar",

    "Quer vender seu imóvel?":"¿Quiere vender su propiedad?",
    "Aqui você conta com a melhor divulgação online e offline, com mais de 100 lojas trabalhando em rede à procura da melhor negociação para você.":"Aquí tienes la mejor divulgación online y offline, con más de 100 tiendas trabajando juntos buscando la mejor oferta para ti.",
    "Veja as vantagens Apolar":"Ver las ventajas de Apolar",

    "Investimento seguro e rentável?":"¿Inversión segura y rentable?",
    "O mercado imobiliário é uma excelente opção. Compre e alugue seu imóvel em um único portal, muito mais segurança para você.":"El mercado inmobiliario es una excelente opción. Compra y alquila tu propiedad en un solo portal. ¡Mucha más seguridad para ti!",
    "Confira as dicas de investimento":"Consulte los consejos de inversión",
    "Anunciar agora":"Anunciar ahora",



    "Procurando um novo lar?":"¿Buscando un nuevo hogar?",
    "Mais de 8.000 opções disponíveis":"Más de 8.000 opciones disponibles",
    "Para venda e locação, 12.000 acessos diários, o nosso portal é a melhor opção para você anunciar o seu imóvel.":"En venta y alquiler, 12.000 accesos diarios, nuestro portal es la mejor opción para que anuncies tu propiedad.",
    "Saiba mais sobre a Apolar":"Obtenga más información sobre Apolar",

    "Alugue sem burocracia":"Alquiler sin burocracia",
    "Com documentação e assinatura digital. Encontre o seu imóvel em apenas um clique, muito mais facilidade para você.":"Con documentación y firma digital. Encuentra tu propiedad en un solo clic, mucho más fácil para ti.",
    "Veja as vantagens Apolar":"Ver las ventajas de Apolar",

    "O imóvel dos seus sonhos está aqui":"La propiedad de tus sueños está aquí",
    "O imóvel dos seus sonhos está aqui. São mais de 5.000 opções disponíveis para venda, encontre o que você procura em um único portal.":"Hay más de 5.000 opciones disponibles para la venta. Encuentra lo que buscas en un solo portal.",
    "Confira as dicas de investimento":"Consulte los consejos de inversión",
    "Buscar imóveis":"Buscar propriedades",

    "Alugar Imóveis": "Alquilar inmuebles",
    "Os melhores imóveis para locação":"Los mejores inmuebles para alquiler",
    "Bairros em Curitiba":"Barrios Curitiba",
    "Os melhores bairros de Curitiba":"Los mejores barrios de Curitiba",
    "Comprar Imóveis":"Comprar inmuebles",
    "Os melhores imóveis para comprar":"Los mejores inmuebles para comprar",

    "Sobre a Apolar": "Acerca de Apolar",
    "FAQ":"Preguntas más frecuentes",
    "Área Restrita":"Área restringida",

    "CONTATO":"CONTACTO",
    "Fale Conosco":"Hable con Nosostros",
    "Trabalhe Conosco":"Trabaje con Nosotros",
    "Seja um Franqueado":"Ser un franquiciado",
    "Franquias":"Franquicias inmobiliarias",

    "PROPRIETÁRIO": "PROPIETARIO",
    "Anuncie seu Imóvel":"Anuncia tu propiedad",
    "Apolar Procura":"Búsqueda apolar",
    "Investimentos":"Inversiones",

    "Alugar em %s": "Alquilar en %s",
    "Comprar em %s": "Comprar en %s",
    "Imóveis no %s": "Bienes raíces en %s",
    "Alugar %s em %s": "Alquilo %s en %s",

    "Copyright – Apolar Imóveis | Todos os direitos reservados":"Copyright – Apolar Imóveis | Todos los derechos reservados",

    "Imóvel com gestão exclusiva Apolar": "Inmueble con gestión exclusiva Apolar",
    "Ver detalhes do imóvel": "Ver detalles de la propiedad",
    "Detalhes do Imóvel": "Detalles de la Propiedad",
    "Proximidades": "Cercanías",
    "Imóveis similares": "Propiedades similares",
    "banheiros": "baños",
    "banheiro": "baño",
    "vagas": "garajes",
    "vaga": "garaje",
    "quartos": "dormitorios",
    "quarto": "dormitorio",
    "mobiliado": "amueblado",
    "Dormitórios": "Dormitórios",
    "Área Terreno": "Área Terreno",
    "Área Total": "Área Total",
    "Área Útil": "Área construída",
    "Garagem": "Estacionamiento",
    "Face": "Orientación",
    "Aluguel": "Alquiler",
    "Condomínio": "Condominio",
    "IPTU":"Impuesto Inmobiliario",
    "Seguro Incêndio":"Seguro contra incendios",
    "consultar valores com a seguradora.": "consultar valores con la aseguradora.",
    "*Valores sujeitos a alteração": "*Valores sujetos a cambios",
    "Gostou deste imóvel?": "¿Te gustó esta propiedad?",
    "Preencha seus dados abaixo":"Completa tus datos abajo",
    "Contato enviado!": "Contacto enviado!",
    "Houve uma falha ao tentar enviar seu contato.":"Se produjo un error al intentar enviar su contacto.",
    "Nome":"Nombre",
    "E-mail":"Correo electrónico",
    "Telefone":"Teléfono",
    "Enviar":"Enviar",
    "Fale pelo Whatsapp":"Habla por Whatsapp",
    "Fale pelo Telefone":"Habla por Teléfono",
    "ENTENDI":"Entendí",
    "Olá preciso de informações sobre esse imóvel: ":"Hola necesito información sobre esta propiedad: ",
    "Entenda o Valor do Aluguel": "Comprenda el Valor del Alquiler",
    "Seguro incêndio": "Seguro contra incendios",
    "Valor sujeito a alteração.": "Valor sujeto a cambios",
    "Inclui proteção obrigatória contra incêndio, queda de raio ou explosão.": "Incluye protección obligatoria contra incendios, caída de rayos o explosiones.",
    "Valor sujeito a alteração conforme deliberações condominiais, podendo, ainda, variar de acordo com as despesas fixas e eventuais do condomínio (ex.: água, luz, conservação e manutenção do prédio, entre outros).":"El valor está sujeto a cambios según las decisiones del condominio, y también puede variar según los gastos fijos y eventuales del condominio. (agua, luz, conservación y mantenimiento del edificio, entre otros).",
    "É um imposto municipal cujo o valor é determinado pela prefeitura.":"Es un impuesto municipal cuyo valor es determinado por la municipalidad.",
    "O valor do aluguel é %s com abatimento de %s para pagamento em dia, e com este benefício o aluguel sai por %s":"El valor del alquiler es de %s con un descuento de %s por pago puntual, y con este beneficio, el alquiler queda en %s",
    "Quer entrar em contato conosco? Dúvidas sobre algum produto ou serviço da Apolar?": "¿Desea ponerse en contacto con nosotros? ¿Tiene preguntas sobre algún producto o servicio de Apolar?",
    "Criamos este espaço especialmente para você fazer comentários, sugestões e esclarecer dúvidas.": "Hemos creado este espacio especialmente para que usted pueda hacer comentarios, sugerencias y aclarar dudas.",
    "Horário de atendimento":"Horario de atención",
    "De segunda à sexta-feira, entre 8h às 19h. Aos sábados, entre 9h às 14h.":"De lunes a viernes, de 8:00 a 19:00. Los sábados, de 9:00 a 14:00.",
    "Procurando uma Apolar especifica?":"¿Buscando una sucursal específica de Apolar?",
    "Encontre entre as nossas":"Encuéntrala entre nuestras",
    "franquias":"franquicias",
    "Contato enviado!": "¡Contacto enviado!",
    "Entre em contato":"Póngase en contacto",
    "Nome completo":"Nombre completo",
    "Mensagem":"Mensaje",
    "Procurando algo a mais entre em contato": "Si estás buscando algo más, ponte en contacto",
    "Dúvidas que talvez você tenha":"Dudas que tal vez puedas tener",
    "Tenho um imóvel para vender ou alugar. O que devo fazer?":"Tengo una propiedad para vender o alquilar. ¿Qué debo hacer?",
    "Basta clicar em <a targer='_blank' href='https://www.apolar.com.br/anuncie/'>Anuncie</a> ou entrar em contato com uma das nossas lojas. Nossos corretores estão preparados para fazer a melhor avaliação do mercado, garantindo agilidade no negócio.":"Solo tienes que hacer clic en <a targer='_blank' href='https://www.apolar.com.br/anuncie/'>'Anunciar'</a> o ponerte en contacto con una de nuestras sucursales. Nuestros corredores están preparados para realizar la mejor evaluación del mercado, asegurando agilidad en el negocio.",
    "Por que devo escolher a Apolar para administrar o meu imóvel?":"¿Por qué debería elegir a Apolar para administrar mi propiedad?",
    "A Apolar tem mais de 50 anos de tradição no mercado imobiliário e se destaca pela força de divulgação, com mais de 100 lojas interligadas, que trabalham em rede, e equipe de especialistas na oferta do imóvel. Além de parceria com os principais portais imobiliários, onde seu imóvel será anunciado, divulgação nas redes sociais e no próprio portal Apolar, que tem mais de 200 mil acessos/mês. A Apolar também trabalha com uma equipe jurídica própria, com advogados especialistas na área imobiliária, que fornecem toda assessoria necessária para os clientes.":"Apolar tiene más de 50 años de tradición en el mercado inmobiliario y se destaca por su fuerte difusión, con más de 100 tiendas interconectadas que trabajan en red, y un equipo de especialistas en la oferta de propiedades. Además de la colaboración con los principales portales inmobiliarios, donde se anunciará su propiedad, la difusión en redes sociales y en el propio portal de Apolar, que recibe más de 200 mil visitas al mes. Apolar también cuenta con un equipo legal propio, con abogados especializados en el área inmobiliaria, que brindan toda la asesoría necesaria para los clientes.",
    "Estou procurando um imóvel para alugar ou comprar, com quem devo falar?":"Estoy buscando una propiedad para alquilar o comprar, ¿con quién debo hablar?",
    "O portal da Apolar é bem completo e atualizado. Com a ajuda dos filtros avançados de busca, você pode navegar e buscar opções de acordo com a sua necessidade. Também temos o serviço de chat online, que oferece agilidade no atendimento. Se quiser nos visitar, será um prazer recebê-lo em uma das nossas unidades. Encontre a mais próxima de você.":"El portal de Apolar es muy completo y actualizado. Con la ayuda de los filtros avanzados de búsqueda, puedes navegar y buscar opciones de acuerdo a tus necesidades. También contamos con el servicio de chat en línea, que ofrece rapidez en la atención. Si deseas visitarnos, será un placer recibirte en una de nuestras sucursales. Encuentra la más cercana a ti.",
    "Que tipo de documentação preciso para alugar/comprar ou vender um imóvel?":"¿Qué tipo de documentación necesito para alquilar/comprar o vender una propiedad?",
    "Para alugar basta apresentar seu RG e CPF para que sua análise cadastral possa ser realizada.<br>\
    Para vender é necessário o documento do imóvel, seu RG e CPF e comprovante do estado civil.<br>\
    Para comprar o imóvel é necessário apresentar RG e CPF, comprovante do estado civil, além de certidões pessoais em caso de financiamento.<br>\
    *Outros documentos podem ser necessários na dependência de situações especiais. Nesses casos, o corretor/analista fornecerá as orientações pertinentes.":"Para alquilar, solo necesitas presentar tu RG y CPF para que se pueda realizar el análisis crediticio.<br>\
    Para vender, se requiere el documento de la propiedad, tu RG y CPF, y un comprobante de estado civil.<br>\
    Para comprar la propiedad es necesario presentar el RG y CPF, un comprobante de estado civil, además de certificados personales en caso de financiamiento.<br>\
    *Otros documentos pueden ser requeridos en situaciones especiales. En estos casos, el corredor/analista proporcionará las orientaciones pertinentes.",
    "Sou inquilino (a) da Apolar e estou com um problema no imóvel. A quem devo procurar?":"Soy inquilino(a) de Apolar y tengo un problema en la propiedad. ¿A quién debo buscar?",
    "A Apolar oferece assistência técnica 24h, de forma gratuita. Basta ligar para o telefone 0800 707 79 56 e agendar uma visita.":"Apolar ofrece asistencia técnica las 24 horas, de forma gratuita. Solo tienes que llamar al teléfono 0800 707 79 56 y programar una visita.",

    "Sobre a Apolar":"Acerca de Apolar",
    "Pensou imóveis, Pense Apolar": "Si piensas en bienes raíces, piensa en Apolar",
    "Nosso slogan representa o que fazemos, trabalhamos com imóveis em diferentes frentes, trazemos as soluções que você procura no mercado imobiliário.":"Nuestro lema representa lo que hacemos, trabajamos con propiedades en diferentes frentes, traemos las soluciones que buscas en el mercado inmobiliario.",
    "A confiança que conquistamos de nossos clientes é refletida em nossa carteira com mais de 5.000 propriedades locadas e 10.000 imóveis disponíveis para venda e locação.":"La confianza que hemos ganado de nuestros clientes se refleja en nuestra cartera con más de 5,000 propiedades alquiladas y 10,000 propiedades disponibles para venta y alquiler.",
    "Investimos em tecnologia, usabilidade e design para garantir que haja segurança, conforto e agilidade em cada etapa da sua experiência com a Apolar.":"Invertimos en tecnología, usabilidad y diseño para asegurar que haya seguridad, comodidad y rapidez en cada etapa de tu experiencia con Apolar.",
    "Desde o momento do contato com uma de nossas lojas, passando pelos anúncios, até a etapa final de compra, venda ou locação.":"Desde el momento del contacto con una de nuestras tiendas, pasando por los anuncios, hasta la etapa final de compra, venta o alquiler.",
    "Dessa forma nós já ajudamos milhares de proprietários e inquilinos a ter êxito na experiência no atendimento Apolar.":"De esta manera, hemos ayudado a miles de propietarios e inquilinos a tener éxito en su experiencia con el servicio de Apolar.",
    "Diferenciais":"Diferenciales",
    "Mais de 50 anos de atuação no mercado imobiliário;":"50 años de experiencia en el mercado inmobiliario;",
    "A única com selo AABF, excelência em franchising;":"La única con el sello AABF, excelencia en franquicias;",
    "10.000 imóveis disponíveis;":"10,000 propiedades disponibles;",
    "9.000 acessos no portal Apolar diariamente;":"9,000 accesos diarios al portal Apolar;",
    "Acessos ao redor do mundo;":"Accesos desde todo el mundo;",
    "Rede integrada com mais de 60 lojas.":"Red integrada con más de 60 tiendas.",
    "História":"Historia",
    "Fundada em 1969, por Joseph Galiano, a Apolar Imóveis se consolidou como a principal e mais lembrada imobiliária do Paraná. Atuou como cônsul da França em Curitiba e sempre foi respeitado pela sua ética, dedicação e empreendedorismo.":"Fundada en 1969 por Joseph Galiano, Apolar Inmuebles se consolidó como la principal e inolvidable agencia inmobiliaria de Paraná. Actuó como cónsul de Francia en Curitiba y siempre fue respetada por su ética, dedicación y espíritu emprendedor.",
    "O nome Apolar surgiu devido ao projeto Apollo 11, quando o homem comprovou ser possível pisar na lua.":"El nombre \"Apolar\" surgió debido al proyecto Apollo 11, cuando el hombre demostró que era posible pisar la luna.",
    "Em 1995, Jean-Michel e Daniel Galiano tornaram a Apolar, pioneira no processo de Franchising no Mercado Imobiliário, a única com selo ABF de qualidade. Hoje somos uma rede integrada com mais de 60 lojas distribuídas no Paraná, Santa Catarina, São Paulo, EUA e Europa.":"En 1995, Jean-Michel y Daniel Galiano hicieron que Apolar fuera pionera en el proceso de franquicias en el mercado inmobiliario, convirtiéndola en la única con el sello ABF de calidad. Hoy somos una red integrada con más de 60 tiendas distribuidas en Brasil, Paraguay, Estados Unidos e Europa.",

    "Propósito":"Objetivo",
    "Missão":"Misión",
    "Proporcionar a melhor experiência a seus clientes na prestação de serviços imobiliários.":"Brindar la mejor experiencia a sus clientes en la prestación de servicios inmobiliarios.",
    "Visão":"Visión",
    "Ser uma rede sustentável de negócios imobiliários que gere felicidade às pessoas.":"Ser una red sostenible de negocios inmobiliarios que genere felicidad a las personas.",
    "Respeito":"Respeto",
    "Agilidade":"Agilidad",
    "Inovação":"Innovación",
    "Flexibilidade":"Flexibilidad",
    "Sinergia":"Sinergia",
    "Desenvolvimento pessoal":"Desarrollo personal",
    "Sentimento de pertencimento":"Sentimiento de pertenencia",
    "Gastar menos do que se ganha":"Gastar menos de lo que se gana",

    "Parceiros":"Colaboradores",

    "Tem sempre uma apolar perto de você! A Apolar Imóveis possui mais de 100 pontos de atendimento. Confira abaixo onde está a nossa bandeira.": "¡Siempre hay una Apolar cerca de ti! Apolar Inmuebles cuenta con más de 100 puntos de atención. Consulta a continuación dónde está nuestra bandera.",

    "Selecione uma Cidade": "Seleccione una ciudad",
    "Vendas":"Ventas",
    "CARREGAR MAIS LOJAS": "CARGAR MÁS FRANQUICIAS",
    "Imóveis em Portugal": "Propiedades en Portugal",
    "Preencha seus dados abaixo e entraremos em contato": "Complete sus datos abajo y nos pondremos en contacto",
    "Outros valores": "Otros valores",
    "Ver no Mapa": "Ver en el mapa",
    "com": "con",
    "em": "en",
    "Venda": "Venta",
    "Imóveis para alugar": "Inmuebles para alquilar",
    "Imóveis à venda": "Inmuebles en venta",
    "Limpar tudo": "Limpiar todo",
    "Pesquisa atual": "Búsqueda actual",
    "Nenhum filtro": "Ningún filtro",
    "Referência": "Referencia",
    "Digite uma referência":"Ingrese una referencia",
    "Visualização": "Visualización",
    "Grid": "Cuadrícula",
    "Preço (menor - maior)": "Precio (menor - mayor)",
    "Preço (maior - menor)": "Precio (mayor - menor)",
    "Outros tipos de imóveis": "Otros tipos de inmuebles",
    "Tipo de imóveis": "Tipos de inmuebles",
    "Quantidade de Quartos": "Cantidad de Habitaciones",
    "Faixa de preço": "Rango de precios",
    "Quantidade de garagens": "Cantidad de garajes",
    "Quantidade de banheiros": "Cantidad de baños",
    "Apartamento": "Departamento",
    "Casa e sobrado": "Casa y dúplex",
    "Studio e kitnet": "Estudio",
    "Terreno": "Terreno",
    "Garden": "Jardín",
    "Cobertura": "Penthouse",
    "2º andar": "2º piso",
    "Academia": "Gimnasio",
    "Almoxarifado": "Depósito",
    "Andar térreo": "Planta baja",
    "Área comum": "Área común",
    "Área de serviço": "Área de servicio",
    "Área de serviço 2": "Área de servicio 2",
    "Area externa": "Área externa",
    "Atelier": "Taller",
    "Ático": "Ático",
    "Auditório": "Auditorio",
    "Banheiro de serviço": "Baño de servicio",
    "Banheiro social": "Baño social",
    "Banheiro social 2": "Baño social 2",
    "Banheiro social 3": "Baño social 3",
    "Banheiro social 4": "Baño social 4",
    "Banheiro social 5": "Baño social 5",
    "Banheiro suíte": "Baño en suite",
    "Banheiro suíte 2": "Baño en suite 2",
    "Banheiro suíte 3": "Baño en suite 3",
    "Banheiro suíte master": "Baño en suite principal",
    "Barracão": "Tinglado",
    "Barracão 2": "Tinglado 2",
    "Biblioteca": "Biblioteca",
    "Bicicletário": "Bicicletero",
    "Brinquedoteca": "Sala de juegos infantiles",
    "Canil": "Canil",
    "Casa 2": "Casa 2",
    "Casa 3": "Casa 3",
    "Churrasqueira": "Quincho",
    "Churrasqueira 2": "Parrilla",
    "Cinema": "Cine",
    "Circulação": "Circulación",
    "Circulação 2": "Circulación 2",
    "Circulação 3": "Circulación 3",
    "Closet": "Vestidor",
    "Cobertura": "Cobertura",
    "Condomínio": "Condominio",
    "Copa": "Cocina pequeña",
    "Corredor": "Pasillo",
    "Cozinha": "Cocina",
    "Cozinha 2": "Cocina 2",
    "Deck": "Deck",
    "Demi-suíte": "Demi-suite",
    "Dependência de empregada": "Dependencia de servicio",
    "Depósito": "Depósito",
    "Despensa": "Despensa",
    "Despensa 2": "Despensa 2",
    "Dormitório": "Dormitorio",
    "Dormitório 1": "Dormitorio 1",
    "Dormitório 2": "Dormitorio 2",
    "Dormitório 3": "Dormitorio 3",
    "Dormitório 4": "Dormitorio 4",
    "Dormitório 5": "Dormitorio 5",
    "Dormitório 6": "Dormitorio 6",
    "Edícula": "Edícula",
    "Edícula 2": "Edícula 2",
    "Edícula 3": "Edícula 3",
    "Edícula 4": "Edícula 4",
    "Edícula 5": "Edícula 5",
    "Edícula 6": "Edícula 6",
    "Edícula banheiro": "Baño en edícula",
    "Edícula cozinha": "Cocina en edícula",
    "Edícula despensa": "Despensa en edícula",
    "Edícula dormitório 1": "Dormitorio en edícula 1",
    "Edícula dormitório 2": "Dormitorio en edícula 2",
    "Edícula dormitório 3": "Dormitorio en edícula 3",
    "Edícula sala": "Sala en edícula",
    "Edícula varanda": "Varanda en edícula",
    "Entrada": "Entrada",
    "Escada": "Escalera",
    "Escritório": "Oficina",
    "Escritório 2": "Escritorio",
    "Espaço gourmet": "Espacio gourmet",
    "Espaço pet": "Espacio pet",
    "Estacionamento": "Estacionamiento",
    "Estagio da obra": "Etapa de obra",
    "Fachada": "Fachada",
    "Frente": "Frente",
    "Fundos": "Fondo",
    "Garagem": "Cochera",
    "Garagem - coberta": "Cochera - cubierta",
    "Garagem - coberta e descoberta": "Cochera - cubierta y descubierta",
    "Garagem - coletiva": "Cochera - colectiva",
    "Garagem - descoberta": "Cochera - descubierta",
    "Garagem - individual": "Cochera - individual",
    "Garagem - individual 2": "Cochera - individual 2",
    "Garagem - individual 3": "Cochera - individual 3",
    "Garden": "Jardín con terraza",
    "Gazebo": "Gazebo",
    "Hall": "Hall",
    "Jardim": "Jardín",
    "Jardim de inverno": "Jardin de interno",
    "Lago": "Lago",
    "Lateral": "Lateral",
    "Lavabo": "Tocador",
    "Lavabo 2": "Tocador 2",
    "Lavanderia": "Lavandería",
    "Localização": "Ubicación",
    "Loja": "Tienda",
    "Loja 2": "Local",
    "Mezanino": "Entrepiso",
    "Mini campo de golf": "Mini campo de golf",
    "Ofurô": "Jacuzzi",
    "Piscina": "Piscina",
    "Piso inferior": "Piso inferior",
    "Piso superior": "Piso superior",
    "Planta baixa": "Planta baja",
    "Playground": "Área de juegos",
    "Porão": "Sótano",
    "Portaria": "Portería",
    "Projeto": "Proyecto",
    "Quadra de beach tênis": "Cancha de beach tennis",
    "Quadra poliesportiva": "Cancha polideportiva",
    "Quintal": "Patio",
    "Recepção": "Recepción",
    "Rodapés": "Zócalos",
    "Sacada": "Balcón",
    "Sacada 2": "Balcón 2",
    "Sacada 3": "Balcón 3",
    "Sacada 4": "Balcón 4",
    "Sala": "Sala",
    "Sala 2": "Sala 2",
    "Sala 3": "Sala 3",
    "Sala 4": "Sala 4",
    "Sala 5": "Sala 5",
    "Sala 6": "Sala 6",
    "Sala 7": "Sala 7",
    "Sala 8": "Sala 8",
    "Sala comercial": "Sala comercial",
    "Sala de estar": "Sala de estar",
    "Sala de ginástica": "Sala de gimnasia",
    "Sala de jantar": "Comedor",
    "Sala de reuniões": "Sala de reuniones",
    "Sala de tv": "Sala de tv",
    "Salão de festas": "Salón de fiestas",
    "Salão de festas 2": "Salón de fiestas 2",
    "Salão de festas infantil": "Salón de fiestas infantil",
    "Salão de jogos": "Sala de juegos",
    "Sauna": "Sauna",
    "Sótão": "Sótano",
    "Spa": "Spa",
    "Suíte": "Suite",
    "Suíte 2": "Suite 2",
    "Suíte 3": "Suite 3",
    "Suíte master": "Suite principal",
    "Terraço": "Terraza",
    "Terreno": "Terreno",
    "Terreno 2": "Terreno 2",
    "Térreo": "Planta baja",
    "Varanda": "Varanda",
    "Vista": "Vista",
    "Imóvel para morar":"Propiedad para vivir",
    "Apartamento": "Departamento",
    "Área": "Área",
    "Casa": "Casa",
    "Duplex": "Dúplex",
    "Flat": "Flat",
    "Terreno": "Terreno",
    "Área": "Área",
    "Barracão": "Galpón",
    "Casa": "Casa",
    "Conjunto": "Conjunto",
    "Hotel": "Hotel",
    "Loja": "Tienda",
    "Ponto": "Punto",
    "Prédio": "Edificio",
    "Sala": "Sala",
    "Sobreloja": "Sobretienda",
    "Studio": "Estudio",
    "Kitnet": "Kitnet",
    "Sobrado": "Dúplex",
    "Cobertura": "Penthouse",
    "FAQ | Imobiliária em Curitiba - Imóveis à Venda e Locação": "Preguntas frecuentes | Bienes Raíces en Paraguay - Propiedades en Venta y Alquiler",
    "Fale Conosco | Imobiliária em Curitiba - Imóveis à Venda e Locação": "Hable con Nosostros | Bienes Raíces en Paraguay - Propiedades en Venta y Alquiler",
    "Saiba mais sobre nossa historia | Imobiliária em Curitiba - Imóveis à Venda e Locação": "Saber más sobre nuestra historia | Bienes Raíces en Paraguay - Propiedades en Venta y Alquiler",
    "Franquias | Imobiliária em Curitiba - Imóveis à Venda e Locação": "Franquicias | Bienes Raíces en Paraguay - Propiedades en Venta y Alquiler",

}