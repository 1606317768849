<template>
    <div id="app">
        <router-view name="header"></router-view>
        <main :class="`site-lang-${this.lang}`">
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
        </main>
        <router-view name="footer"></router-view>

        <!-- <div v-if="show_blackweek_popup" class="black-week-popup">
          <div @click="closeBlackWeekPopup" class="black-week-backdrop"></div>
          <div class="black-week-content">
            <i @click="closeBlackWeekPopup" class="fa fa-window-close" aria-hidden="true"></i>
            <img @click="navigateBlackWeek" :src="require(`@/assets/img/popup-blackweek.webp`)" alt="Apolar Black Week">
          </div>
        </div> -->
    </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  metaInfo: {
    titleTemplate: '%s | Apolar',
    htmlAttrs: {
      lang: 'pt-BR'
    }
  },
  components: {
    FadeTransition
  },
  data() {
    return {
      show_blackweek_popup: false
    }
  },
  methods: {
    getTagId() {
    },
    closeBlackWeekPopup() {
      this.show_blackweek_popup = false
      this.$cookies.set('blackweek_done', '1') 
    },
    navigateBlackWeek() {
      this.closeBlackWeekPopup()
      window.open('https://www.apolar.com.br/feirao-apolar', '_blank');
    }
  },
  computed: {
    firefly_widget_id: function() {
        //firefly lancamentos
        if (window.location.href.indexOf('/lancamentos') != -1) {
          return 'cl6uy47u2a7jb0g58wroiqmjq'
        } else {
          //fireflyx principal
          return 'cjxo3bfjf08uq07871kx4kpqy'
        }
    }
  },
  mounted() {
    let blackweek_done = this.$cookies.get('blackweek_done')
    if (!blackweek_done) {
      this.show_blackweek_popup = true
    }
  },
  beforeMount(){
    
    
  },
  created() {

      var url = new URL(window.location.href)
      var params = new URLSearchParams(url.search);
      var custom_gtm = params.get('g')

      var head = document.querySelector('head')
      var body = document.querySelector('body')

      var ra_tag_head = document.createElement('script');
      ra_tag_head.type = 'text/javascript';
      ra_tag_head.setAttribute('id', `ra-embed-verified-seal`)
      ra_tag_head.setAttribute('src', `https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js`)
      ra_tag_head.setAttribute('data-id', `MzYwMDphcG9sYXItaW1vdmVpcw==`)
      ra_tag_head.setAttribute('data-target', `ra-verified-seal`)
      ra_tag_head.setAttribute('data-model', `2`)
      head.append(ra_tag_head);

      if (custom_gtm) {
          custom_gtm = atob(custom_gtm)
          console.log("GTM: ", custom_gtm)

          var gtag_head = document.createElement('script');
          gtag_head.type = 'text/javascript';
          gtag_head.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',"${custom_gtm}");`;
          head.append(gtag_head);

          var gtag_body_noscript = document.createElement('noscript');
          var gtag_body_iframe = document.createElement('iframe');
          gtag_body_iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${custom_gtm}`)
          gtag_body_iframe.setAttribute("height", "0")
          gtag_body_iframe.setAttribute("width", "0")
          gtag_body_iframe.setAttribute("style", "display:none;visibility:hidden")
          gtag_body_noscript.appendChild(gtag_body_iframe)
          body.appendChild(gtag_body_noscript)
      }
    
  }
};
</script>
