<template>
  <div class="page-principal-franchise">
        <div class="container">
            <h1>{{ __("Franquias") }}</h1>

            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <div class="description">
                        {{ __("Tem sempre uma apolar perto de você! A Apolar Imóveis possui mais de 100 pontos de atendimento. Confira abaixo onde está a nossa bandeira.") }}
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-3">
                <div class="col-sm-12 col-md-3">
                    <v-select
                        :options="cities"
                        :placeholder="__('Selecione uma Cidade')"
                        v-model="city"
                        @input="filter"
                    >
                    </v-select>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-12 franchise-list">
                    <template v-for="item,idx in list">
                        <div v-bind:key="`item_${idx}`" class="franchise-item">
                            <div class="business">{{__(item.negocio)}}</div>
                            <div class="franchise-name">Apolar - {{__(item.negocio).toUpperCase()}} {{item.nomeloja}}</div>
                            <div class="franchise-address">{{item.endereceo}}</div>

                            <a class="franchise-page" :alt="`Apolar - ${item.nomeloja}`" target="_blank" v-if="item.url_loja" :href="item.url_loja">
                                {{item.url_loja.replace('https://','')}}
                            </a>
                            <span class="franchise-page" v-else>-</span>

                            <ul class="info">
                                <li>
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                    <span v-html="item.franequeado_email ? item.franequeado_email : '-'" ></span>
                                </li>
                                <li>
                                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                                    {{item.work_time_start && item.work_time_end ? `${item.work_time_start} às ${item.work_time_end}` : '08:00 às 18:00'}}
                                </li>
                                <li>
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    <span v-html="item.franqueado_telefone ? item.franqueado_telefone : '-'" ></span>
                                </li>
                                <li class="pointer" @click="sendWhatsapp(item.WhatsApp_Loja)">
                                    <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                    <span v-html="item.WhatsApp_Loja ? item.WhatsApp_Loja : '-'" ></span>
                                </li>
                            </ul>

                        </div>
                    </template>
                </div>
            </div>

            <div v-if="list.length < total" class="row">
                <div class="col-sm-12 col-md-12">
                    <button @click="loadMore" class="load-more">{{ __("CARREGAR MAIS LOJAS") }}</button>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/_franchise.scss"
import FranchiseService from "../../services/FranchiseService"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  name: "franchise",
  components: {
    vSelect
  },
  metaInfo() {
    return {
      title: this.__("Franquias | Imobiliária em Curitiba - Imóveis à Venda e Locação"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content:"Acesse aqui e conheça agora mesmo todas as nossas lojas franqueadas! Pensou Imóveis, pense Apolar!"
        }
      ]
    };
  },
  data() {
    return {
        cities: [],
        list: [],
        nextList: null,
        total: 0,
        city: null,
    };
  },
  computed: {


  },
  methods: {
    search(data={}) {
        FranchiseService.search(data).then(resp=>{
            this.list = this.list.concat(resp.data)
            this.nextList = resp.scroll_id
            this.total = resp.total
        })
    },

    loadMore() {
        let data = {
            'scroll_id': this.nextList
        }
        this.search(data)
    },

    filter() {
        this.list = []
        let data = {
            'city': this.city
        }
        this.search(data)
    },

    sendWhatsapp(whatsapp) {

        whatsapp = whatsapp.replace(/\D/g,'')
        let url = `https://api.whatsapp.com/send?phone=55${whatsapp}`
        window.open(url, '_blank').focus();
    }
  },
  mounted() {
    FranchiseService.getFilters().then(resp=>{
        this.cities = resp.data.cities
    })

    this.search()
    
  }
};
</script>
