<template>
  <section class="property-header-default-full">
      <section class="property-header-default">
      <div v-show="current_tab == 'photo'" id="property-slide">


        
        <div v-if="photos.length > 0" class="gallery-with-thumb">
          <div class="main-slide">

             <div class="resume-property-box">
                <div class="basic-info">
                  <div class="property-title">{{property_title}}</div>
                  <div class="address"> {{immobile.endereco}}, {{immobile.numero}}, {{immobile.bairro}} - {{immobile.cidade}}</div>
                  <div class="property-ref">Ref. {{immobile.referencia}}</div>
                </div>

                <div class="separator"></div>
                
                <div class="resume-midle-box">
                  <ul class="property-highlights">
                    <template v-for="highlight, idx in property_highlights">
                      <li class="highlight" :key="idx">
                        <img width="1" height="1" :class="highlight.icon" :src="highlight.new_icon" :alt="highlight.value">
                        <span class="highlight-value">
                          {{highlight.value}}

                            <span v-if="highlight.icon == 'room' && immobile.suites" class="small">
                            (
                              {{immobile.suites}}
                              <span v-if="immobile.suites >1">suites</span>
                              <span v-else>suite</span>
                            )
                          </span>
                        </span>
                      </li>
                    </template>
                  </ul>

                  <ul class="property-features-blue-box">
                      <li v-for="feature in immobile.features.slice(0,6)">
                        {{feature}}
                      </li>
                    </ul>
                </div>

                <div class="separator"></div>

                <div class="price-info">
                  <div class="price-area">
                    <label for="">A partir de</label>
                    <span>R$ {{number_format(immobile.valor_considerado,2,',','.')}}</span>
                  </div>
                  <div class="button-description" @click="scrollTo('.property-details', -100)">
                    Ver descrição
                  </div>
                </div>
              </div>

            <VueSlickCarousel
              ref="gallery_main_slide"
              v-bind="main_slide_settings"
              @beforeChange="syncSliders"
            >
              <img
                v-for="photo,k in photos"
                :src="photo.url"
                :alt="photo.comodo"
                @click="showPropetySlidePopupAction"
                v-bind:key="`photo_${k}`"
              />
            </VueSlickCarousel>

           
          </div>
          <div class="thumb-slide">
            <VueSlickCarousel
              ref="gallery_thumb_slide"
              v-bind="thumb_slide_settings"
              @beforeChange="syncSliders"
            >
              <img v-for="photo in photos" :src="photo.url" :alt="photo.comodo" />
            </VueSlickCarousel>
          </div>
        </div>
      </div>

      <div v-show="current_tab == 'map'" id="property-map">
        <!-- <iframe :src="this.map" frameborder="0"></iframe> -->
        <Openstreet
          :refresh="current_tab == 'map'"
          :properties="[this.immobile]"
        ></Openstreet>
      </div>

      <div v-show="current_tab == 'video' && video != ''" id="property-video">
        <iframe :src="this.video" frameborder="0"></iframe>
      </div>

      <div v-show="current_tab == 'tour' && tour != ''" id="property-tour">
        <div v-on:click="current_tab = 'photo'" class="close-popup">
          Fechar <i class="fa fa-times-circle-o" aria-hidden="true"></i>
        </div>
        <iframe :src="this.tour" frameborder="0"></iframe>
      </div>

      <div v-show="showPropetySlidePopup" class="propety-slide-popup">
        <div v-on:click="showPropetySlidePopup = false" class="close-popup">
          Fechar <i class="fa fa-times-circle-o" aria-hidden="true"></i>
        </div>

        <VueSlickCarousel
          ref="full_carousel_photos"
          v-bind="main_slide_settings"
          @beforeChange="syncSliders"
          class="carousel-popup-main-photos"
        >
          <img v-for="photo in photos" :src="photo.url" :alt="photo.comodo" />
        </VueSlickCarousel>

        <div class="thumbs">
          <div class="photo-name">
            {{ current_photo_label }}
          </div>

          <div class="photo-count">
            {{ popup_photo_count }}
          </div>

          <VueSlickCarousel
            ref="full_carousel_photos_thumb"
            @beforeChange="syncSliders"
            :centerMode="true"
            :infinite="true"
            :slidesToShow="10"
          >
            <img v-for="photo in photos" :src="photo.url" :alt="photo.comodo" />
          </VueSlickCarousel>
        </div>
      </div>

      <div v-if="reserved" class="stamp-reserved">EM NEGOCIAÇÃO</div>
    </section>
  </section>
  
</template>
<script>
import Vue from "vue";
import jQuery from "jquery";
import { Carousel, Slide } from "vue-carousel";
import Openstreet from "../openstreet.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    Carousel,
    Slide,
    Openstreet,
    VueSlickCarousel
  },
  props: {
    immobile: {
      default: {},
    },
  },
  computed: {
    area_info() {
        return this.areaTypes ? `${Math.trunc(this.immobile.areaterreno)} m²` : `${Math.trunc(this.immobile.area_total)} m²`
    },
    property_highlights: function() {
  
      var highlights = []

      if ( Object.keys(this.immobile).length > 0 ) {

        highlights.push({
          "icon": "total_area",
          "new_icon": require("@/assets/img/lancamentos/details/details-rule-white.webp"),
          "value": `${this.area_info}`
        })

        if (this.immobile.banheiro > 0) {
          highlights.push({
            "icon": "bathrooms",
            "new_icon": require("@/assets/img/lancamentos/details/details-bath-white.webp"),
            "value": `${this.immobile.banheiro} ${ this.immobile.banheiro > 1 ? this.__("banheiros") : this.__("banheiro") }`
          })
        }

        if (this.immobile.garagem > 0) {
          highlights.push({
            "icon": "garage",
            "new_icon": require("@/assets/img/lancamentos/details/details-garage-white.webp"),
            "value": `${this.immobile.garagem} ${ this.immobile.garagem > 1 ? this.__("vagas") : this.__("vaga") }`
          })
        }

        if (this.immobile.dormitorios > 0) {
          highlights.push({
            "icon": "room",
            "new_icon": require("@/assets/img/lancamentos/details/details-room-white.webp"),
            "value": `${this.immobile.dormitorios} ${ this.immobile.dormitorios > 1 ? this.__("quartos") : this.__("quarto") }`
          })
        }

        if (this.immobile.mobiliado && this.immobile.mobiliado.toLowerCase() != "n") {
          highlights.push({
            "icon": "furnished",
            "new_icon": require("@/assets/img/lancamentos/details/details-room-white.webp"),
            "value": this.__(`mobiliado`)
          })
        }

        if (this.immobile.AceitaPets) {
          highlights.push({
            "icon": "pet",
            "new_icon": require("@/assets/img/lancamentos/details/details-room-white.webp"),
            "value": `pet`
          })
        }
      }

      return highlights
    },
    property_title: function() {
      var title = ""

      if (Object.keys(this.immobile).length > 0) {

        if (this.immobile.condominio) {
          title = this.immobile.condominio.length > 30 ? `${this.immobile.condominio.slice(0, 29)}.` : this.immobile.condominio
        } else {
          title = `${this.immobile.tipo} ${this.immobile.finalidade} para ${(this.immobile.transacao == "Vendas" ? "Venda" : "Locação")} no ${this.immobile.bairro_list[0]}`
        }

      }

      return title
    },
    current_photo_label: function () {
      return this.photos[this.current_photo_popup]["comodo"];
    },
    popup_photo_count: function () {
      return `${this.current_photo_popup + 1}/${this.photos.length}`;
    },
    reserved: function () {
      if (
        typeof this.immobile.situacao != "undefined" &&
        this.immobile.situacao.toLowerCase().indexOf("reservad") != -1
      ) {
        setTimeout(() => {
          jQuery(".stamp-reserved").css({
            right: jQuery("h1.property-title").offset().left,
          });
        }, 1000);
        return true;
      } else {
        return false;
      }
    },
    main_slide_settings: function () {
      return {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        focusOnSelect: true,
      };
    },
    thumb_slide_settings: function () {
      return {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        vertical: false,
        focusOnSelect: true,
        infinite: true,
      };
    },
  },
  data() {
    return {
      current_tab: "photo",
      photos: [],
      current_photo_number: 1,
      map: "",
      video: "",
      tour: "",
      showPropetySlidePopup: false,
      force_thumb_nav: null,
      current_photo_popup: 0,
    };
  },
  methods: {
    showPropetySlidePopupAction() {
      this.showPropetySlidePopup = true;

      setTimeout(() => {
        // let heightSliderPopup = jQuery(".propety-slide-popup .carousel-popup-main-photos").height()

        // jQuery(".propety-slide-popup .carousel-popup-main-photos").css({
        //   "top": `30%`,
        //   "margin-top": `-${(heightSliderPopup/2)}px`
        // })
        console.log(this.$refs);

        this.$refs["carousel_photos_main"].onDrag(() => {});
      }, 100);
    },

    changeThumbSlide(page) {
      console.log("oiii thumb", page);
      this.force_thumb_nav = page;
      this.current_photo_popup = page;

      console.log("thumv ref: ", this.$refs["carousel_photos_thumb"]);
      this.$refs["carousel_photos_thumb"].goTo(page);
    },

    changeMainSlide(page) {
      console.log("oiii thumb", page);
    },

    changeTab(tab) {
      this.current_tab = tab;
    },
    getUrlParameter(url, name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var results = regex.exec(url);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    shareThis: function () {
      if (navigator.share) {
        navigator.share({
          title: `${this.immobile.condominio} - Apolar Imóveis`,
          url: window.location.href,
        });
      }
    },

    syncSliders: function (currentPosition, nextPosition) {

      this.force_thumb_nav = nextPosition;
      this.current_photo_popup = nextPosition;

      this.$refs["gallery_main_slide"].goTo(nextPosition);
      this.$refs["gallery_thumb_slide"].goTo(nextPosition);
      this.$refs["full_carousel_photos"].goTo(nextPosition);
      this.$refs["full_carousel_photos_thumb"].goTo(nextPosition);
      this.current_photo_number = nextPosition + 1;
    },

    scrollTo: function(elem, diff=0) {
       let pos =  jQuery(elem).offset().top
       pos += diff
       jQuery([document.documentElement, document.body]).animate({
          scrollTop: pos
      }, 1000);
    }
  },
  mounted() {

    let checkLoadedResume = setInterval(() => {
      let resume_box = document.querySelector(".resume-property-box")
      if ( resume_box ) {
        resume_box.style.marginTop = `-${resume_box.clientHeight / 2}px`
        clearInterval(checkLoadedResume)
      }
    }, 100);
    
    jQuery(".property-header-menu").css({
      left: jQuery("h1.property-title").offset().left,
    });

    jQuery(".share-property").css({
      left:
        jQuery(".property-details-sidebar").offset().left +
        jQuery(".property-details-sidebar").width() -
        40,
    });

    var initClick = 0;
    var endClick = 0;
    jQuery("body")
      .delegate(".property-slide-item", "mousedown", () => {
        initClick = new Date().getTime() / 1000;
      })
      .delegate(".property-slide-item", "mouseup", () => {
        console.log("oi.....click..");
        endClick = new Date().getTime() / 1000;
        //console.log((endClick - initClick))
        if (endClick - initClick < 0.15) {
          this.showPropetySlidePopupAction();
        }
      });

    
    
  },
  watch: {
    immobile: function (immobile) {
      console.log("header immobile: ", immobile);
      console.log("immobile photo: ", immobile.popup_fotos.Foto);

      var photos = [];
      immobile.popup_fotos.Foto.forEach((item) => {
        let comodo =
          typeof item.COMODO != "undefined" ? item.COMODO[0] : item.Comodo[0];
        let photo = {
          comodo: comodo,
          url: item.URLArquivo[0],
        };
        photos.push(photo);
      });
      Vue.set(this, "photos", photos);

      const lat = immobile.EixoX;
      const lng = immobile.EixoY;
      this.map = `https://maps.google.com/maps?t=m&hl=pt-BR&gl=US&mapclient=embed&ie=UTF8&ll=${lat},${lng}&spn=${lat},${lng}&z=14&q=${lat},${lng}&output=embed`;

      if (typeof immobile.video != "undefined" && immobile.video.trim() != "") {
        var video = "";
        if (immobile.video.indexOf("yout") != "-1") {
          video =
            "https://www.youtube.com/embed/" +
            this.getUrlParameter(immobile.video, "v");
        }
        if (immobile.video.indexOf("youtu.be") != "-1") {
          var video_parts = immobile.video.split("/");
          var videoId = video_parts[video_parts.length - 1];
          video = "https://www.youtube.com/embed/" + videoId;
        }
        Vue.set(this, "video", video);
      }

      if (
        typeof immobile.urlFotos360 != "undefined" &&
        immobile.urlFotos360 != null &&
        immobile.urlFotos360 != ""
      ) {
        var tour = immobile.urlFotos360.replace("http:", "https:");
        Vue.set(this, "tour", tour);
      }
    },
  },
};
</script>
<style>
</style>